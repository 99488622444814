import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface NavbarLinkProps {
	name: string;
	subtitle: string;
	address: string;
	selected: boolean;
	onClick: () => void;
}

export default function NavbarLink(props: NavbarLinkProps) {
	
	return (
		<Container to={props.address} onClick={props.onClick} selected={props.selected}>
			<AnimationContainer>
				<span>{props.name}</span>
				<Subtitle>{props.subtitle}</Subtitle>
			</AnimationContainer>
		</Container>
	)
}

const Subtitle = styled.div``;

const AnimationContainer = styled.div``;

const AnimationSettings = '200ms cubic-bezier(0.145, 0.295, 0.000, 0.995)';

const Container = styled(Link)<{ selected: boolean }>`
	max-height: 59px;
	min-height: 59px;
	overflow: hidden;
	color: white;

	span {
		${props => props.selected ? 'color: red;' : ''}
	}

	${AnimationContainer} {
		display: flex;
		flex-direction: column;
		transform: translateY(10px);
		transition: transform ${AnimationSettings};

		${Subtitle} {
			margin-top: -10px;
			font-family: wotfardlight;
			opacity: 0;
			transition: opacity ${AnimationSettings}, margin-top ${AnimationSettings};
		}
	}

	&:hover {
		cursor: pointer;
		color: white;

		${AnimationContainer} {
			transform: translateY(0);

			${Subtitle} {
				opacity: 1;
				margin-top: -6px;
			}
		}
	}
`;
