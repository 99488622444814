import React from "react";
import styled from "styled-components";

export default function SomeThatIveTold(props: { bg: string }) {
	return (
		<Container background={props.bg}>
			<div className="inner">
				<div className="row-1">
					<span>HERE ARE SOME</span>
				</div>
				<div className="row-2">
					<span>THAT I'VE TOLD.</span>
				</div>
				<div className="row-3">
					<span>CODEBOTS</span>
				</div>
				<div className="row-4">
					<span>SUZANNESTAYS</span>
				</div>
				<div className="row-5">
					<span>STORYTALE</span>
				</div>
			</div>
		</Container>
	);
}

const Container = styled.div<{ background: string }>`
	height: 100vh;
	width: 100%;
	scroll-snap-align: start;

	color: white;
	font-family: wotfardbold, serif;
	font-size: min(160px, 8vw);

	display: flex;
	align-items: center;
	justify-content: center;

	background: ${props => props.background};
	text-align: left;

	.inner {
		width: 80%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.row-1, .row-2 {
			line-height: 1;
		}

		.row-1 {
			margin-top: 60px;
		}

		.row-3, .row-4, .row-5 {
			font-family: wotfardlight, serif;
			font-size: min(120px, 6vw);
		}

		.row-3, .row-4 {
			text-decoration: underline;
		}

	}
`;