import React from "react";
import styled from "styled-components";
import { Frame, HeaderText } from '../../App';

export default function Storytale() {
	return (
		<Frame>
			<HeaderText>Storytale</HeaderText>
		</Frame>
	);
}