import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Frame, HeaderText } from '../App';
import Charlie from '../Images/charlie.gif';
import ManInField from '../Images/man-in-field.jpeg';
import SomeThatIveTold from "./SomeThatIveTold";
import TellAStory from "./TellAStory";

export default function Home() {

	const CircleTextSize = 300;

	return (<>
		<Frame>
			<HomeContainer>
				<HeaderText className="first">DIGITAL</HeaderText>
				<div className="second">
					<span className="name">Isaac Joe Kong</span>
					<HeaderText>STORYTELLER</HeaderText>
					<span className="subtext">I tell stories through branding, digital products and creative direction.</span>
				</div>
				<img src={ManInField} alt="Man in field" />
			</HomeContainer>
		</Frame>
		<SecondFrame>
			<div className="top-text">What's with all the story stuff?</div>
			<svg xmlns="http://www.w3.org/2000/svg" width="3" height="431">
				<path d="M 1 1.89 L 1 429.11" fill="transparent" stroke-width="2" stroke="#AAA"></path>
			</svg>
			<div className="bottom-text">WELL...</div>
		</SecondFrame>
		<ThirdFrame>
			<div className="stories">
				<span>ST</span>
				<img src={ManInField} alt="Isaac" />
				<span>RIES</span>
			</div>
		</ThirdFrame>
		<ThirdFrame>
			<div className="line" />
			<span className="line-text">DON'T START WITH A HERO.</span>
			<div className="line" />
			<div className="second-last">They start with just another</div>
			<span className="person">PERSON.</span>
		</ThirdFrame>
		<FourthFrame>
			<div className="large">
				<div className="container">
					<div className="object">
						<img src={ManInField} alt="Isaac" />
					</div>
					<div className="object">
						<DrawCircleText
							style={{ stroke: "#FFF", letterSpacing: "-4px", fontSize: "125px", fontWeight: 700 }}
							textColor="#FFF"
							height={850}
							size={"320"}
							className="first-ring"
							text="AND THEY GO ON AN ADVENTURE."
						/>
					</div>
					<DrawCircleText
						style={{ stroke: "#161616", letterSpacing: "-5px", fontSize: "178px", fontWeight: 700 }}
						textColor="#161616"
						height={1200}
						size={"460"}
						className="second-ring"
						text="AND THEY GO ON AN ADVENTURE."
					/>
				</div>
			</div>
			<div className="medium">
				<div className="container">
					<div className="object">
						<img src={ManInField} alt="Isaac" />
					</div>
					<div className="object">
						<DrawCircleText
							style={{ stroke: "#FFF", letterSpacing: "-4px", fontSize: "78px", fontWeight: 700 }}
							textColor="#FFF"
							height={600}
							size={"200"}
							className="first-ring"
							text="AND THEY GO ON AN ADVENTURE."
						/>
					</div>
					<DrawCircleText
						style={{ stroke: "#161616", letterSpacing: "-5px", fontSize: "110px", fontWeight: 700 }}
						textColor="#161616"
						height={900}
						size={"280"}
						className="second-ring"
						text="AND THEY GO ON AN ADVENTURE."
					/>
				</div>
			</div>
		</FourthFrame>
		<FifthFrame>
			<div className="container">
				<div className="row-1">
					<span>DOING</span>
					<span>QUESTS</span>
					<span>🧙‍♂️,</span>
				</div>
				<i className="row-2">SOLVING PROBLEMS</i>
				<div className="row-3">
					<span>AND SLAYING DRAGONS.🐲</span>
					<img src="https://source.unsplash.com/random/535x345" alt="aragorn" />
				</div>
			</div>
		</FifthFrame>
		<SixthFrame>
			<div className="cover">
				<span>I HELP HEROES TO</span>
				<span>TELL THESE STORIES.</span>
			</div>
		</SixthFrame>
		<SeventhFrame>
			<div className="inner">
				<div className="row-1">
					<span>THROUGH</span>
					<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50">
						<path d="M 25 0 C 38.807 0 50 11.193 50 25 C 50 38.807 38.807 50 25 50 C 11.193 50 0 38.807 0 25 C 0 11.193 11.193 0 25 0 Z" fill="rgb(242, 79, 47)"></path>
					</svg>
				</div>
				<div className="row-2">
					<span>Product design</span>
					<span>Writing</span>
				</div>
				<div className="row-3">
					<i>Creative direction</i>
				</div>
				<div className="row-4">
					<span>Branding</span>
					<span>Visual design</span>
				</div>
				<div className="row-5">
					<span>Illustrations</span>
				</div>
			</div>
		</SeventhFrame>
		<SomeThatIveTold bg="var(--blue)" />
		<TellAStory />
	</>);
}

const SeventhFrame = styled.div`
	scroll-snap-align: start;
	height: 120vh;
	width: 100%;

	color: white;
	font-family: wotfardbold, serif;
	font-size: 110px;
	font-size: min(110px, 6vw);

	display: flex;
	align-items: center;
	justify-content: center;

	.inner {
		width: 80%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.row-1 {
			display: flex;
			width: 100%;
			margin-bottom: 32px;
			span {
				font-size: 32px;
				margin-right: auto;
			}
		}

		.row-2 {
			span:nth-of-type(2) {
				margin-left: 64px;
				font-family: "Calendas Plus Regular", serif;
			}
		}

		.row-3 {
			color: var(--reed);
			font-family: "Calendas Plus Regular", serif;
		}

		.row-4 {
			span:nth-of-type(1) {
				font-family: wotfardthin, serif;
			}
			span:nth-of-type(2) {
				margin-left: 64px;
				font-family: "Calendas Plus Bold", serif;
			}
		}

		.row-5 {
			font-family: wotfardlight, serif;
		}

	}
`;

const SixthFrame = styled.div`
	scroll-snap-align: start;
	background-image: url(${Charlie});
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	width: 100%;

	.cover {
		width: 100%;
		height: 100%;
		background: #000000d1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	color: white;
	font-family: wotfardbold, serif;
	font-size: min(132px, 7vw);
`;

const FifthFrame = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	scroll-snap-align: start;

	.container {
		width: 80%;
		display: flex;
		flex-direction: column;
		color: white;
		font-family: wotfardbold, serif;
		font-size: min(132px, 7vw);

		.row-1 {
			display: flex;

			span:nth-of-type(2) {
				color: var(--reed);
				text-decoration: underline;
				margin-left: 24px;
			}
		}

		.row-2 {
			color: white;
			font-family: wotfardregular, serif;
		}

		.row-3 {
			display: flex;

			img {
				height: auto;
				width: min(535px, 28vw);
			}
		}
	}

`;

const FourthFrame = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	scroll-snap-align: start;

	.large {
		display: block;
	}

	.medium {
		display: none;
	}

	.container {
		margin-top: -15vh;
		position: relative;
		z-index: -1;
		margin-bottom: 280px;

		.object {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;
		}

		img {
			width: 520px;
			height: 520px;
			border-radius: 50%;
			overflow: hidden;
		}
	
		.first-ring {
			transform: rotateZ(275deg);
		}
	}

	@media (max-width: 1000px) {

		.medium {
			display: block;
		}

		.large {
			display: none;
		}

		.container {
			position: relative;
			z-index: -1;
			margin-bottom: 280px;
	
			.object {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				top: 0;
				left: 0;
			}
	
			img {
				width: 350px;
				height: 350px;
				border-radius: 50%;
				overflow: hidden;
			}
		
			.first-ring {
				transform: rotateZ(275deg);
			}
		}
	}
		

`;

const ThirdFrame = styled.div`
	background: var(--reed);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	scroll-snap-align: start;
	height: 100vh;

	.stories {

		display: flex;
		font-family: wotfardbold, serif;
		font-size: min(524px, 22vw);
		line-height: 0.8;
		
		img {
			--img-size: min(454px, 18vw);
			width: var(--img-size);
			height: var(--img-size);
			border-radius: 50%;
			overflow: hidden;
		}

	}

	.line {
		width: 80%;
		min-height: 8px;
		max-height: 8px;
		background: white;
	}

	.line-text {
		font-family: wotfardbold, serif;
		font-size: min(100px, 5vw);
		line-height: 3;
	}

	.second-last {
		margin-top: 75px;
		font-size: 48px;
		font-family: wotfardregular, serif;
	}

	.person {
		text-decoration: underline;
		font-size: 48px;
		font-family: wotfardbold, serif;
	}

`;

const SecondFrame = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -12vh;

	.top-text {
		font-size: 36px;
		color: white;
		margin-bottom: 12px;
	}

	.bottom-text {
		margin-top: 85px;
		font-size: 24px;
		color: white;
	}
`;

const HomeContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 85%;
	position: relative;

	img {
		position: absolute;
		width: 500px;
		height: 500px;
		border-radius: 50%;
		overflow: hidden;
		right: 0;
		z-index: -1;
	}

	.first {
		align-self: flex-start;
	}

	.second {
		align-self: flex-end;
		margin-top: -32px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.name {
			align-self: flex-end;
			font-size: 32px;
			font-family: wotfardlight;
			color: #ffffff;
			margin-right: 116px;
		}

		.subtext {
			font-size: 24px;
			font-family: wotfardlight;
			color: #ffffff;
			max-width: 500px;
			margin-left: 8px;
			margin-top: 8px;
		}
	}
`;

const DrawCircleText = (props: { size: string, className: string, height: number, text: string, textColor: string, style: CSSProperties }) => {
	const CircleTextSize = props.size;
	const id = `${props.className}${props.size}-${props.height}`;
	return <svg className={props.className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" height={`${props.height}px`} width={`${props.height}px`}>
		<path id={id} d={`M ${CircleTextSize},0 A ${CircleTextSize},${CircleTextSize} 0 0 1 -${CircleTextSize},0 A ${CircleTextSize},${CircleTextSize} 0 0 1 ${CircleTextSize},0`} transform={`translate(${props.height / 2},${props.height / 2})`} fill="none" stroke-width="50" />
		<text style={props.style} fill={props.textColor} stroke-width="0">
			<textPath xlinkHref={`#${id}`}><tspan dy="1">{props.text}</tspan></textPath>
		</text>
	</svg>
}