import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Frame, HeaderText } from '../App';
import TellAStory from "./TellAStory";

const Verticaldistance = "20%";
const Horizontaldistance = "15%";

export default function Quests() {

	const QuestLink = styled(Link)`
		font-size: min(100px, 5vw);
		font-family: "Wotfard Bold", serif;
		color: var(--reed);
		font-weight: 700;
		text-decoration: none!important;

		&:hover {
			cursor: pointer;
		}
	`;

	return (<>
		<Frame>
			<QuestLink to="/quests/codebots" style={{ position: "absolute", top: Verticaldistance, left: Horizontaldistance }}>Codebots</QuestLink>
			<QuestLink to="/quests/workingmouse" style={{ position: "absolute", top: Verticaldistance, right: Horizontaldistance }}>WorkingMouse</QuestLink>
			<QuestLink to="/quests/suzannestays" style={{ position: "absolute", bottom: Verticaldistance, left: Horizontaldistance }}>SuzanneStays</QuestLink>
			<QuestLink to="/quests/storytale" style={{ position: "absolute", bottom: Verticaldistance, right: Horizontaldistance }}>Storytale</QuestLink>
			<FrameOneContainer>
				<HeaderText>QUESTS</HeaderText>
			</FrameOneContainer>
		</Frame>
		<TellAStory />
	</>);
}

const FrameOneContainer = styled.div`

`;