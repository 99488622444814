import React from "react";
import styled from "styled-components";

export default function TellAStory() {
	return (
		<Container>
			<div className="inner">
				<div className="row-1">
					WANT TO TELL A STORY <span>TOGETHER</span>?
				</div>
				<div className="row-2">
					<div className="studio">
						<span>Studio</span>
						<Card
							imageUrl="https://source.unsplash.com/random/238x250"
							name="Storytale Studio"
							text="Hire for work"
						/>
					</div>
					<div className="connect">
						<span>Connect with me</span>
						<div className="cards-container">
							<Card
								imageUrl="https://source.unsplash.com/random/239x250"
								name="Instagram"
								text="Slide into my DMs."
							/>
							<Card
								imageUrl="https://source.unsplash.com/random/237x250"
								name="Dribble"
								text="Design experiments"
							/>
							<Card
								imageUrl="https://source.unsplash.com/random/238x251"
								name="Linkedin"
								text="That networking thing."
							/>
						</div>
					</div>
				</div>
				<div className="row-3">
					<span>2021 Ijoek - Isaac Joe Kong</span>
					<span>Designed by Ijoek. Developed by Lindsay Spencer.</span>
				</div>
			</div>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	width: 100%;
	scroll-snap-align: start;

	color: white;
	font-family: wotfardbold, serif;
	font-size: min(160px, 8vw);

	display: flex;
	align-items: center;
	justify-content: center;

	text-align: left;

	.inner {
		width: 80%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.row-1 {
			line-height: 1;
			margin-top: 80px;
			margin-bottom: 20px;
			span {
				color: var(--reed);
			}
		}

		.row-2 {
			display: flex;
			flex-wrap: wrap;
			font-size: 24px;

			.studio {
				margin-right: 112px;
			}

			.studio > span, .connect > span {
				margin-bottom: 24px;
				display: block;
				line-height: 1;
			}

			.cards-container {
				display: grid;
				grid-auto-flow: column;
				gap: 24px;
			}
		}

		.row-3 {
			display: flex;
			justify-content: space-between;
			font-size: 16px;
			width: 100%;
			font-family: wotfardlight, serif;
			margin-top: 32px;
		}

	}
`;

function Card(props: { name: string, imageUrl: string, text: string }) {
	return (
		<CardContainer>
			<img src={props.imageUrl} alt={props.name} />
			<span className="title">{props.name}</span>
			<span className="description">{props.text}</span>
		</CardContainer>
	)
}

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	color: white;

	img {
		width: 238px;
		height: 250px;
		object-fit: cover;
		margin-bottom: 24px;
	}

	.title {
		font-size: 24px;
		text-decoration: underline;
	}

	.description {
		font-size: 16px;
		font-family: wotfardlight, serif;
	}
`;