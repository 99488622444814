import React from "react";
import styled from "styled-components";
import { Frame, HeaderText } from '../App';
import TellAStory from "./TellAStory";

export default function Deeds() {
	return (<>
		<Frame>
			<HeaderText>Deeds</HeaderText>
		</Frame>
		<TellAStory />
	</>);
}