import React, { useState } from 'react';
import {
	BrowserRouter as Router,
	Link, Route, Switch
} from "react-router-dom";
import styled from 'styled-components';
import './App.css';
import ScrollToTop from './Hooks/ScrollToTop';
import NavbarLink from './NavbarLink';
import { Adventures, Deeds, Dragons, Home, Quests } from './Pages';
import { Codebots, Storytale, SuzanneStays, WorkingMouse } from './Pages/Quests/';

const NavBar = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	color: white;
	width: 100%;
	z-index: 1;
`;

const NavContainer = styled.div`
	margin: 91px 80px 0px 80px;
	display: flex;
	align-items: center;
	font-family: wotfardbold;
	font-size: 24px;

	.left {
		margin-right: auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.title {
    		color: white;
			transition: color 150ms;

			&:hover {
				cursor: pointer;
			}
		}

		.subtitle {
			font-family: wotfardlight;
		}
	}

	.right {
		display: flex;

		> a {
			margin-left: 0px;

			&+ a {
				margin-left: 43px;
			}
		}
	}
`;

const navLinks = [
	{
		subtitle: "Process",
		name: "Adventures",
		address: "/adventures"
	},
	{
		subtitle: "Projects",
		name: "Quests",
		address: "/quests"
	},
	{
		subtitle: "About",
		name: "Deeds",
		address: "/deeds"
	},
	{
		subtitle: "Stories",
		name: "Dragons",
		address: "/dragons"
	}
];

type LinkType = {
	subtitle: string;
	name: string;
	address: string;
}

function App() {

	const [selectedLink, setSelectedLink] = useState<LinkType>();

	return (
		<Router>
			<SnapScrollFrame>
				<ScrollToTop />
				<NavBar>
					<NavContainer>
						<div className="left">
							<Link className="title" to={"/"}>IJOEK</Link>
							<span className="subtitle">Storyteller</span>
						</div>
						<div className="right">
							{navLinks.map(link => <NavbarLink selected={link === selectedLink} onClick={() => setSelectedLink(link)} subtitle={link.subtitle} name={link.name} address={link.address} />)}
						</div>
					</NavContainer>
				</NavBar>
				<Switch>
					<Route exact path="/"
						render={({ match: { url } }) => {
							setSelectedLink(undefined);
							return <Home />
						}}
					/>
					<Route path="/adventures"
						render={({ match: { url } }) => {
							setSelectedLink(navLinks.find(x=>x.address===url));
							return <Adventures />
						}}
					/>
					<Route
						path="/quests"
						render={({ match: { url } }) => {
							setSelectedLink(navLinks.find(x=>x.address===url));
							return (
								<>
									<Route path={`${url}/`} component={Quests} exact />
									<Route path={`${url}/codebots`} component={Codebots} />
									<Route path={`${url}/workingmouse`} component={WorkingMouse} />
									<Route path={`${url}/suzannestays`} component={SuzanneStays} />
									<Route path={`${url}/storytale`} component={Storytale} />
								</>
							)
						}
					}
					/>
					<Route path="/deeds"
						render={({ match: { url } }) => {
							setSelectedLink(navLinks.find(x=>x.address===url));
							return <Deeds />
						}}
					/>
					<Route path="/dragons"
						render={({ match: { url } }) => {
							setSelectedLink(navLinks.find(x=>x.address===url));
							return <Dragons />
						}}
					/>
				</Switch>
			</SnapScrollFrame>
		</Router>
	);
}

export default App;

export const OrangeText = styled.span`
	font-family: wotfardbold, serif;
	color: var(--reed);
	font-weight: 700;
`;

export const HeaderText = styled(OrangeText)`
	font-size: min(200px, 10vw);
	line-height: 0.9;
`;

export const Frame = styled.div`
	--width: 100%;
	--height: 100vh;
	min-width: var(--width);
	max-width: var(--width);
	min-height: var(--height);
	max-height: var(--height);
	display: flex;
    align-items: center;
    justify-content: center;
	position: relative;
	scroll-snap-align: start;
`;

const SnapScrollFrame = styled.div`
`;