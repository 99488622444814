import React from "react";
import styled from "styled-components";
import { Frame, HeaderText } from '../../App';

export default function Codebots() {
	return (
		<Frame>
			<HeaderText>Codebots</HeaderText>
		</Frame>
	);
}