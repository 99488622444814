import React from "react";
import styled from "styled-components";
import { Frame, HeaderText } from '../App';
import TellAStory from "./TellAStory";

export default function Dragons() {
	return (<>
		<Frame>
			<HeaderText>Dragons</HeaderText>
		</Frame>
		<TellAStory />
	</>);
}