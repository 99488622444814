import React from "react";
import styled from "styled-components";
import { Frame, HeaderText } from '../App';
import SomeThatIveTold from "./SomeThatIveTold";
import TellAStory from "./TellAStory";

export default function Adventures() {
	return (<>
		<Frame>
			<HeaderText>ADVENTURE</HeaderText>
			<ShapeContainer>
				<BlueCircle>
					<WhiteCircle />
				</BlueCircle>
			</ShapeContainer>
		</Frame>
		<SomeThatIveTold bg="var(--reed)" />
		<TellAStory />
	</>);
}

const BlueCircleThickness = 36;
const WhiteCircleSize = 82;

const WhiteCircle = styled.div`
	background: white;
	width: ${WhiteCircleSize}px;
	height: ${WhiteCircleSize}px;
	border-radius: 50%;
	position: absolute;
	top: -${BlueCircleThickness+WhiteCircleSize/4}px;
    left: calc(40vh - ${WhiteCircleSize/2}px);
`;

const BlueCircle = styled.div`
	background: transparent;
	box-shadow: 0px 0px 0px ${BlueCircleThickness}px var(--blue);
	height: 80vh;
	width: 80vh;
	border-radius: 50%;
	position: relative;
`;

const ShapeContainer = styled.div`
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;